/* Desktops and laptops ----------- */

#b-video {
    z-index: -2;
    position: fixed;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(-50%) translateY(-50%);
}

@media only screen
and (min-width : 1224px) {
    #b-video {
        top: 40%;
    }
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
    #b-video {
        top: 50%;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
    /* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
    /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
    /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    /* Styles */
}
/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
    /* Styles */
}